<template>
  <v-layout class="mb-2">
    <v-text-field
      :label="label"
      outlined
      required
      :rules="rules"
      v-model="text"
      :class="[
        'default-input-style',
        noMargins ? 'mx-0' : hasItems ? 'ml-4' : 'mx-4',
      ]"
      :error="hasEmptyError"
      :error-messages="hasEmptyError && 'Pole nie może być puste'"
      :disabled="disabled"
      :hide-details="hideDetails"
      :readonly="readonly"
      :clearable="clearable"
      @keydown.space="handleSpace"
      :validate-on-blur="validateOnBlur"
      clear-icon="mdi-close"
      light
    >
      <template v-if="autosave" slot="append">
        <AutosaveIcon :value="status"></AutosaveIcon>
      </template>
      <template v-if="searchIcon" slot="append">
        <v-icon size="20">mdi-magnify</v-icon>
      </template>
    </v-text-field>
    <v-layout
      align-center
      shrink
      :class="['mb-6', hasItems && 'mr-3']"
    >
      <slot name="header-items"></slot>
    </v-layout>
  </v-layout>
</template>

<script>
import ModificationMixin from "@/mixins/ModificationMixin";

export default {
  mixins: [ModificationMixin],
  props: {
    value: {},
    label: {},
    placeholder: {},
    disabled: {
      default: false,
    },
    rules: {},
    required: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      default: false,
    },
    searchIcon: {
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    readonly: {
      default: false,
    },
    emptyError: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    trim: {
      type: Boolean,
      default: false,
    },
    validateOnBlur: {
      type: Boolean,
      default: false,
    },
    noMargins: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AutosaveIcon: () => import("@/components/AutosaveIcon.vue"),
  },
  computed: {
    text: {
      get() {
        return this.value;
      },
      set(value) {
        if (this.trim) {
          this.$emit("update:value", value.trim());
        } else {
          this.$emit("update:value", value);
        }
      },
    },
    hasEmptyError() {
      return !this.value && this.emptyError;
    },
    hasItems() {
      return !!this.$slots["header-items"];
    },
  },
  methods: {
    handleSpace(event) {
      if (this.trim) {
        event.preventDefault();
        return;
      }
    },
  },
  mounted() {
    if (this.autosave) {
      this.startWatching("text");
    }
  },
};
</script>

<style lang="scss" scoped>
.zg-text-field-error-empty {
  display: inline-flex;
}
.zg-text-field-error-empty ::v-deep fieldset {
  border-color: var(--v-error-base);
  border-style: solid;
  border-width: 2px !important;
}

.zg-text-field-error-empty ::v-deep .v-input__slot input::placeholder {
  color: var(--v-error-base) !important;
}
</style>